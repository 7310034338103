
import { Component, Vue } from 'vue-property-decorator'
import { PriceInfo } from '@/types/product.d'
import { twoDecimal, integerWithin } from '@/utils/validate'

import { ElForm } from 'element-ui/types/form'

interface ProductNewInfo {
  typePrice: string;
  typeAddTime: string;
  typeUserName: string;
  typeTaxIncluded: string;
  typeInvoicingTaxRate: string;
  typeBillingType: string;

  purchasePrice: string;
  purchaseAddTime: string;
  purchaseUserName: string;
  purchaseTaxIncluded: string;
  purchaseInvoicingTaxRate: string;
  purchaseBillingType: string;

  quotaPrice: string;
  quotaAddTime: string;
  quotaUserName: string;
  quotaTaxIncluded: string;
  quotaInvoicingTaxRate: string;
  quotaBillingType: string;
}

@Component({
  name: 'PriceMenage'
})
export default class extends Vue {
  private priceAuth = {}
  private info: ProductNewInfo = {
    typePrice: '',
    typeAddTime: '',
    typeUserName: '',
    typeTaxIncluded: '',
    typeInvoicingTaxRate: '',
    typeBillingType: '',

    purchasePrice: '',
    purchaseAddTime: '',
    purchaseUserName: '',
    purchaseTaxIncluded: '',
    purchaseInvoicingTaxRate: '',
    purchaseBillingType: '',

    quotaPrice: '',
    quotaAddTime: '',
    quotaUserName: '',
    quotaTaxIncluded: '',
    quotaInvoicingTaxRate: '',
    quotaBillingType: ''
  }

  private searchInfo = {
    priceType: '',
    dateRange: []
  }

  private loading = false
  private list: Array<PriceInfo> = []
  private total = 0
  private page = 1
  private size = 10
  private isAddPrice = false
  private priceInfo = {
    priceType: '',
    price: '',
    addTime: '',
    taxIncluded: '0',
    invoicingTaxRate: '',
    billingType: ''
  }

  private priceRule = {
    priceType: [
      { required: true, message: '请选择类型', trigger: ['blur', 'change'] }
    ],
    price: [
      { required: true, message: '请输入金额', trigger: ['blur', 'change'] },
      { validator: twoDecimal, trigger: ['blur', 'change'] }
    ],
    addTime: [
      { required: true, message: '请选择日期', trigger: ['blur', 'change'] }
    ],
    taxIncluded: [
      { required: true, message: '请选择是否含税', trigger: ['blur', 'change'] }
    ],
    invoicingTaxRate: [
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ]
  }

  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getAuth()
    this.getNewProductInfo()
    this.getPriceList()
  }

  // 获取用户价格权限
  getAuth () {
    this.$axios.get(this.$apis.product.selectLoginUserPriceSet).then((res) => {
      this.priceAuth = res
    })
  }

  // 最新价格
  getNewProductInfo () {
    this.$axios.get<Array<PriceInfo>>(this.$apis.product.selectNewPriceInfoList, {
      productId: this.productId
    }).then((res) => {
      this.info = {
        typePrice: '',
        typeAddTime: '',
        typeUserName: '',
        typeTaxIncluded: '',
        typeInvoicingTaxRate: '',
        typeBillingType: '',

        purchasePrice: '',
        purchaseAddTime: '',
        purchaseUserName: '',
        purchaseTaxIncluded: '',
        purchaseInvoicingTaxRate: '',
        purchaseBillingType: '',

        quotaPrice: '',
        quotaAddTime: '',
        quotaUserName: '',
        quotaTaxIncluded: '',
        quotaInvoicingTaxRate: '',
        quotaBillingType: ''
      }
      res && res.forEach(item => {
        switch (item.priceType) {
          case '3':
            this.info.typePrice = item.price
            this.info.typeAddTime = item.addTime
            this.info.typeUserName = item.addUserName
            this.info.typeTaxIncluded = item.taxIncluded
            this.info.typeInvoicingTaxRate = item.invoicingTaxRate
            this.info.typeBillingType = item.billingType
            break
          case '2':
            this.info.purchasePrice = item.price
            this.info.purchaseAddTime = item.addTime
            this.info.purchaseUserName = item.addUserName
            this.info.purchaseTaxIncluded = item.taxIncluded
            this.info.purchaseInvoicingTaxRate = item.invoicingTaxRate
            this.info.purchaseBillingType = item.billingType
            break
          case '1':
            this.info.quotaPrice = item.price
            this.info.quotaAddTime = item.addTime
            this.info.quotaUserName = item.addUserName
            this.info.quotaTaxIncluded = item.taxIncluded
            this.info.quotaInvoicingTaxRate = item.invoicingTaxRate
            this.info.quotaBillingType = item.billingType
            break
        }
      })
    })
  }

  // 获取价格列表
  getPriceList () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<PriceInfo> }>(this.$apis.product.selectPriceInfoByPage, {
      productId: this.productId,
      page: this.page,
      size: this.size,
      priceType: this.searchInfo.priceType,
      startTime: this.searchInfo.dateRange ? this.searchInfo.dateRange[0] : '',
      endTime: this.searchInfo.dateRange ? this.searchInfo.dateRange[1] : ''
    }).then((res) => {
      this.list = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getPriceList()
  }

  // 新增
  onAdd () {
    this.isAddPrice = true
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该价格吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deletePriceInfo, {
        priceId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getNewProductInfo()
        this.getPriceList()
      })
    })
  }

  // 提交新增产品价格
  submitAddPrice () {
    (this.$refs.addForm as ElForm).validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.product.insertPriceInfo, {
          productId: this.productId,
          ...this.priceInfo
        }).then(() => {
          this.$message.success('添加成功')
          this.onCancel()
          this.getNewProductInfo()
          this.getPriceList()
        })
      }
    })
  }

  // 取消
  onCancel () {
    (this.$refs.addForm as ElForm).resetFields()
    this.isAddPrice = false
  }
}
